<template>
  <div>
    <referral-codes-edit />
    <referral-codes-list />
  </div>
</template>

<script>
import ReferralCodesEdit from '@/views/apps/referral/referral-codes/ReferralCodesEdit.vue'
import ReferralCodesList from '@/views/apps/referral/referral-codes/ReferralCodesList.vue'

export default {
  name: 'ReferralCode',
  components: {
    ReferralCodesEdit,
    ReferralCodesList,
  },
}
</script>

<style scoped>

</style>
