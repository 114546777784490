<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <!--      <div class="alert-heading">-->
      <!--        <b-media-->
      <!--          no-body-->
      <!--        >-->
      <!--          <b-media-aside>-->
      <!--            <feather-icon-->
      <!--              icon="LinkIcon"-->
      <!--              size="18"-->
      <!--            />-->
      <!--          </b-media-aside>-->
      <!--          <b-media-body class="align-self-center">-->
      <!--            <span>{{ $t('referral.referral_links') }}</span>-->
      <!--          </b-media-body>-->
      <!--        </b-media>-->

      <!--      </div>-->
      <div class="alert-body">
        <b-row>
          <b-col
            cols="12"
            sm="8"
            align-self="center"
          >
            <p class="ml-1">
              {{ $t('referral.share_referral_links_intro') }}
            </p>
          </b-col>
          <b-col
            cols="12"
            sm="4"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              class="mt-1 mt-sm-0"
              @click="addCode()"
            >
              <span class="align-middle">{{ $t('referral.create_referral_link') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <!-- Modal Form -->
    <b-modal
      id="modal-form"
      ref="modal-form"
      v-model="modal"
      centered
      hide-footer
      :static="true"
    >
      <!-- Modal Form Header -->
      <template #modal-header>
        <b-container
          fluid
          class="text-primary"
        >
          <b-button
            class="close btn-icon"
            variant="outline-primary "
            @click="modal=false"
          >
            <span>x</span>
          </b-button>
          <b-media
            no-body
          >
            <b-media-aside>
              <b-avatar
                rounded
                variant="primary"
                size="24"
              >
                <feather-icon
                  icon="LinkIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <span>{{ $t('referral.referral_link') }}</span>
            </b-media-body>
          </b-media>
        </b-container>
      </template>
      <!-- Modal Form Body -->
      <b-overlay
        :show="submitting"
        variant="white"
        rounded
        opacity="0.6"
        spinner-medium
        spinner-variant="primary"
      >
        <!--Form-->
        <b-container
          v-if="!success"
          fluid
        >
          <validation-observer
            ref="referralForm"
            #default="{invalid}"
          >
            <b-form
              id="referral"
              class="auth-login-form mt-2"
              @submit.prevent="submit"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    class=""
                    label-for="code-desc"
                  >
                    <b-form-textarea
                      id="code-desc"
                      v-model="codeDescription"
                      rows="2"
                      :placeholder="$t('referral.description')"
                    />
                  </b-form-group>
                  <b-form-checkbox
                    v-if="ability.can('update', 'Referral')"
                    v-model="semanticCode"
                    name="custom-code-switch"
                    :value="true"
                    :unchecked-value="false"
                    switch
                    inline
                  >
                    {{ $t('referral.semantic_code') }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row class="mt-1 mb-1">
                <b-col
                  v-if="semanticCode"
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('referral.code')"
                      rules="required|min:8"
                      vid="code"
                    >
                      <b-form-input
                        id="code"
                        :value="code"
                        placeholder="from_facebook_to_job"
                        :state="errors.length > 0 ? false:null"
                        @input="filterCodeInput"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="outline-primary"
                    block
                    :disabled="invalid || submitting"
                    class="mb-1"
                  >
                    <span class="align-middle">{{ $t('referral.create') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <!-- Success  -->
        <b-container
          v-if="success"
          id="referral"
          fluid
          class="text-center"
        >
          <b-avatar
            variant="light-success"
            size="56"
            class="m-1"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="36"
            />
          </b-avatar>
          <p>{{ codeDescription }}</p>
          <b-form-input
            ref="refLink"
            :value="refLink"
            readonly
            class="text-center mt-1 mb-1"
          />
          <b-tooltip
            target="copyLink"
            placement="top"
            triggers="click"
          >
            {{ $t('referral.copied') }}
          </b-tooltip>
          <b-button
            id="copyLink"
            type="submit"
            variant="primary"
            class="m-1"
            @click="copyRefLink()"
          >
            <feather-icon
              icon="Share2Icon"
              class="mr-50"
            />
            <span class="align-middle">
              {{ $t('referral.link') }}
            </span>
          </b-button>
          <b-tooltip
            target="copyLinkAndDescription"
            placement="top"
            triggers="click"
          >
            {{ $t('referral.copied') }}
          </b-tooltip>
          <b-button
            id="copyLinkAndDescription"
            type="submit"
            variant="primary"
            class="m-1"
            @click="copyRefLinkAndDescription()"
          >
            <feather-icon
              icon="Share2Icon"
              class="mr-50"
            />
            <span class="align-middle">
              {{ $t('referral.link') +'+'+ $t('referral.description') }}
            </span>
          </b-button>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BForm,
  BModal,
  BButton,
  BFormCheckbox,
  BContainer,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BAvatar,
  BMedia,
  BMediaBody,
  BMediaAside,
  BOverlay,
  BTooltip,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { useReferral } from '@/views/apps/referral/useReferral'
import { heightFade } from '@core/directives/animations'
import { translateApiErrors } from '@/mixins/functions'
import store from '@/store'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BFormCheckbox,
    BContainer,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BOverlay,
    BTooltip,
    BAlert,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },

  data() {
    return {
      modal: false,
      success: false,
      semanticCode: false,
      codeDescription: '',
      code: '',
      submitting: false,
    }
  },
  computed: {
    refLink() {
      return this.getRefLink()
    },
  },
  created() {
    this.$watch('semanticCode', value => {
      if (!value) {
        this.code = ''
      }
    })
  },
  methods: {
    copyRefLink() {
      const text = this.getRefLink()
      this.copyToClipboard('copyLink', text)
    },
    copyRefLinkAndDescription() {
      const text = this.getRefLinkAndDescriptionByCode()
      this.copyToClipboard('copyLinkAndDescription', text)
    },
    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip(id) {
      this.$root.$emit('bv::hide::tooltip', id)
    },
    copyToClipboard(id, text) {
      navigator.clipboard.writeText(text)
        .then(
          () => {
            this.showTooltip(id)
            setTimeout(
              this.toggleModal,
              1000,
            )
          },
          () => {
            /* clipboard write failed */
          },
        )
    },
    toggleModal() {
      this.success = false
      this.codeDescription = ''
      this.modal = !this.modal
    },
    addCode() {
      this.toggleModal()
    },
    submit() {
      const payload = {
        code: this.code,
        description: this.codeDescription,
      }
      this.submitting = true
      store.dispatch('referral/createReferralCode', payload)
        .then(response => {
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            store.commit('referral/addReferralCode', response.data.data)
            this.success = true
          }
        })
        .catch(error => {
          const { errors } = error.response.data
          translateApiErrors(errors)
          this.$refs.referralForm.setErrors(
            errors,
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
    filterCodeInput(input) {
      this.code = input.replace(/\W/g, '_')
    },
  },

  setup() {
    const {
      getRefLink,
      getRefLinkByCode,
      getRefLinkAndDescriptionByCode,
    } = useReferral()

    const ability = defineAbilityForCurrentUser()

    return {
      ability,
      getRefLink,
      getRefLinkByCode,
      getRefLinkAndDescriptionByCode,
    }
  },
}
</script>
