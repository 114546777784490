<template>
  <section id="promo">
    <b-card>
      <referral />
    </b-card>
  </section>
</template>

<script>

import Referral from '@/views/apps/referral/Referral.vue'

export default {
  components: {
    Referral,
  },

}
</script>
