<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      fill
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab.dataField"
        :href="`#${tab.dataField}`"
        :value="tabIndex"
      >
        <template #title>
          <feather-icon
            :icon="tab.icon"
            size="18"
            class="mr-0 mr-sm-50 d-none d-sm-inline"
          />
          <span>{{ $t(tab.label) }}</span>
        </template>
        <component
          :is="tab.component"
          @sections="setDefaultSections"
        /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol, BButton, BContainer,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ReferralCodes from './referral-codes/ReferralCodes.vue'
import Referrals from './referrals/Referrals.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BButton,
    BContainer,
    ReferralCodes,
    Referrals,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      tabIndex: '',
    }
  },
  watch: {
    '$route.query.tab': {
      handler(search) {
        this.tabIndex = this.tabs.findIndex(tab => tab.dataField === search)
      },
      immediate: true,
    },

  },

  beforeRouteLeave(to, from, next) {
    if (this.accountDataUpdated()) {
      this.showAlert(next)
    } else {
      next()
    }
  },

  methods: {
    setDefaultSections(obj) {
      const tab = Object.keys(obj)[0]
      Object.values(obj)[0].forEach(section => {
        if (!this.accountData[tab][section]) {
          this.accountData[tab][section] = {}
        }
      })
    },
  },
  setup() {
    const tabs = [
      {
        icon: 'LinkIcon',
        dataField: 'referralCodes',
        component: 'ReferralCodes',
        label: 'referral.referral_links',
      },
      {
        icon: 'UsersIcon',
        dataField: 'referrals',
        component: 'Referrals',
        label: 'referral.referrals',
      },
    ]

    return {
      tabs,
    }
  },
}
</script>
<style scoped>
.header-navbar {
  min-height: 0;
  padding: 0.5rem 1rem;

}
.dark-layout .header-navbar {
  background-color: rgba(255, 255, 255,0.5);
}
.header-navbar {
  background-color: rgba(40, 48, 70,0.5);
}
</style>
