<template>
  <div>
    <b-overlay
      :show="submitting"
      variant="white"
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
    >
      <b-list-group flush>
        <b-list-group-item
          v-for="referralCode in referralCodesList"
          :key="referralCode.id"
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
              align-self="center"
              class="text-center text-sm-left"
            >
              <b>{{ referralCode.code }}</b>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              align-self="center"
              class="text-truncate text-center text-sm-left"
            >
              {{ referralCode.description }}
            </b-col>
            <b-col
              cols="12"
              sm="2"
              class="text-center"
            >
              <b-tooltip
                :target="referralCode.code"
                placement="top"
                triggers="click"
              >
                {{ $t('referral.copied') }}
              </b-tooltip>
              <!--              <b-button-->
              <!--                :id="referralCode.code"-->
              <!--                variant="primary"-->
              <!--                class="btn-icon rounded-circle"-->
              <!--                @click="copyRefLink(referralCode.code)"-->
              <!--              >-->
              <!--                <feather-icon-->
              <!--                  icon="Share2Icon"-->
              <!--                  size="16"-->
              <!--                />-->
              <!--              </b-button>-->
              <b-dropdown
                :id="referralCode.code"
                variant="link"
                right
                class="dropdown-icon-wrapper"
              >
                <template #button-content>

                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="cursor-pointer"
                  />
                </template>

                <b-dropdown-item>
                  <feather-icon icon="Share2Icon" />
                  <span
                    class="align-middle ml-50"
                    @click.prevent="copyRefLink(referralCode.code)"
                  >{{ $t('referral.link') }}</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="Share2Icon" />
                  <span
                    class="align-middle ml-50"
                    @click.prevent="copyRefLinkAndDescription(referralCode.code)"
                  >{{ $t('referral.link') +'+'+ $t('referral.description') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
  </div>

</template>

<script>

import {
  BRow,
  BCol,
  BOverlay,
  BTooltip,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { useReferral } from '@/views/apps/referral/useReferral'
import { heightFade } from '@core/directives/animations'
import { translateApiErrors } from '@/mixins/functions'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },

  data() {
    return {
      submitting: false,
      refLinkCopied: false,
      referralCodesList: [],
    }
  },
  created() {
    this.fetchReferralCodes()
  },
  methods: {
    showTooltip(id) {
      this.$root.$emit('bv::show::tooltip', id)
    },
    hideTooltip(id) {
      this.$root.$emit('bv::hide::tooltip', id)
    },
    copyToClipboard(code, text) {
      if (!text) return
      navigator.clipboard.writeText(text)
        .then(
          () => {
            this.showTooltip(code)
            setTimeout(code => {
              this.hideTooltip(code)
            }, 1000)
          },
          () => {
            /* clipboard write failed */
          },
        )
    },
    copyRefLink(code) {
      const text = this.getRefLinkByCode(code)
      this.copyToClipboard(code, text)
    },
    copyRefLinkAndDescription(code) {
      const text = this.getRefLinkAndDescriptionByCode(code)
      this.copyToClipboard(code, text)
    },
    fetchReferralCodes() {
      const payload = {
        order: 'desc',
        per_page: 100,
        sort_by: 'id',
      }
      this.submitting = true
      store.dispatch('referral/getReferralCodes', payload)
        .then(response => {
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            store.commit('referral/setReferralCodes', response.data.data)
            this.referralCodesList = store.getters['referral/referralCodes']
            this.success = true
          }
        })
        .catch(error => {
          const { errors } = error.response.data
          translateApiErrors(errors)
          this.$refs.referralForm.setErrors(
            errors,
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },

  setup() {
    const {
      getRefLinkByCode,
      getRefLinkAndDescriptionByCode,
    } = useReferral()

    return {
      getRefLinkByCode,
      getRefLinkAndDescriptionByCode,
    }
  },
}
</script>
